import styled from "@emotion/styled";
import { TOP_LAYER, LAYER_ONE } from "constants/z-index";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
import BodyConstants from "segments/desktop/constants";
import DesktopHeaderConstants from "segments/desktop/header/layouts/components/basic-header/constants";
import { FULL_HD_DESKTOP_DYNAMIC_TEMPLATE_WIDTH, MIN_HEADER_DESKTOP_MENU } from "constants/vp-sizes";
const BODY_HORIZONTAL_PADDING = BodyConstants.BODY_CONTENT_HORIZONTAL_PADDING;
const HEADER_LOGO_HORIZONTAL_PADDING = DesktopHeaderConstants.HEADER_CONTENT_HORIZONTAL_PADDING;
export const StyledVideoBackgroundAssetComponent = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  overflow: hidden;

  .video-wrapper {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    position: relative;
    ${({ videoHeight })=>videoHeight ? `height: ${videoHeight}` : ""};

    &.hide-black-bars {
      ${({ segmentHeight })=>segmentHeight ? `height: ${segmentHeight}` : ""};
      ${({ segmentMinHeight })=>segmentMinHeight ? `min-height: ${segmentMinHeight}` : ""};
      ${({ segmentMaxHeight })=>segmentMaxHeight ? `max-height: ${segmentMaxHeight}` : ""};
    }

    &.round-corners {
      ${({ videoBorderRadius })=>videoBorderRadius ? `border-radius: ${videoBorderRadius}` : ""};
      overflow: hidden;
    }

    .video-component {
      ${({ isCentrilizeContent, videoPlayerPositionLeft })=>{
    if (isCentrilizeContent) return `
          left: ${videoPlayerPositionLeft !== null && videoPlayerPositionLeft !== void 0 ? videoPlayerPositionLeft : ""} !important;
          display: flex;
          align-items: center;
          justify-content: center;
          `;
}}
      &.hide-black-bars {
        ${({ segmentHeight })=>segmentHeight ? `height: ${segmentHeight}` : ""};
        ${({ segmentMinHeight })=>segmentMinHeight ? `min-height: ${segmentMinHeight}` : ""};
        ${({ segmentMaxHeight })=>segmentMaxHeight ? `max-height: ${segmentMaxHeight}` : ""};
      }

      .react-player-video-component {
        position: absolute !important;
        width: ${({ videoPlayerWidth })=>videoPlayerWidth !== null && videoPlayerWidth !== void 0 ? videoPlayerWidth : ""} !important;
        min-width: ${({ videoPlayerMinWidth })=>videoPlayerMinWidth !== null && videoPlayerMinWidth !== void 0 ? videoPlayerMinWidth : ""} !important;
        height: ${({ videoPlayerHeight })=>videoPlayerHeight !== null && videoPlayerHeight !== void 0 ? videoPlayerHeight : ""} !important;
        top: ${({ videoPlayerPositionTop })=>videoPlayerPositionTop !== null && videoPlayerPositionTop !== void 0 ? videoPlayerPositionTop : ""} !important;

        ${({ isCentrilizeContent, videoPlayerPositionLeft })=>{
    if (!isCentrilizeContent) return `left: ${videoPlayerPositionLeft !== null && videoPlayerPositionLeft !== void 0 ? videoPlayerPositionLeft : ""} !important;`;
}}
        video {
          height: unset !important;
        }
      }
    }

    .dark-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.7);
      z-index: ${TOP_LAYER};
    }
  }

  .content-wrapper {
    width: 100%;
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    z-index: ${LAYER_ONE};
    display: flex;
    flex-direction: column;
    justify-self: center;

    &.align-to-logo {
      max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px;
      padding-left: ${HEADER_LOGO_HORIZONTAL_PADDING}px;
      padding-right: ${HEADER_LOGO_HORIZONTAL_PADDING}px;
    }

    .full-paragraph-component {
      ${({ isCentrilizeContent })=>isCentrilizeContent ? `margin: auto;` : `margin-left: 0;
        margin-right: 500px;`}

      margin-top: ${({ paragraphMarginTop })=>paragraphMarginTop !== null && paragraphMarginTop !== void 0 ? paragraphMarginTop : ""};
      margin-bottom: ${({ paragraphMarginBottom })=>paragraphMarginBottom !== null && paragraphMarginBottom !== void 0 ? paragraphMarginBottom : ""};

      .full-paragraph-wrapper {
        padding: 0 !important; // we have to use !important here because the padding is set in the component itself

        .full-paragraph {
          .button-wrapper {
            margin-top: 12px;
          }
        }
      }
    }

    .top-logos-component {
      margin: 0 auto;
      margin-bottom: ${({ logosBottomMargin })=>logosBottomMargin !== null && logosBottomMargin !== void 0 ? logosBottomMargin : ""};

      .logos {
        max-width: none;

        .image-gallery {
          height: min(calc((100vw / ${FULL_HD_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}) * 122), 122px);
          width: min(calc((100vw / ${FULL_HD_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}) * 150), 150px);

          img {
            max-height: min(calc((100vw / ${FULL_HD_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}) * 69), 69px);
            max-width: min(calc((100vw / ${FULL_HD_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}) * 118), 118px);
          }
        }
      }
    }
  }

  @media (max-width: ${MIN_HEADER_DESKTOP_MENU}px) {
    ${({ isCentrilizeContent })=>{
    if (!isCentrilizeContent) return `
      .content-wrapper {
        .full-paragraph-component {
          h1 {
            font-size: 48px;
          }
  
          h2 {
            font-size: 16px;
          }
        }
  
        .top-logos-component {
          margin-bottom: 0;
        }
      }
      `;
}}
  }
`;
