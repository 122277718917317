import { SIGNUP_BUTTON_TYPE } from "segments/desktop/core-components/button/button-consts";
import { WHITE_COLOR_NAME } from "styles/color-consts";
export default {
    addDarkOverlay: true,
    hideBlackBars: true,
    videoPlayerWidth: "100%",
    videoPlayerHeight: "100%",
    videoPlayerPositionTop: "0",
    videoPlayerPositionLeft: "0",
    fullParagraphConfig: {
        titleColor: WHITE_COLOR_NAME,
        bodyColor: WHITE_COLOR_NAME,
        buttonConfig: {
            buttonType: SIGNUP_BUTTON_TYPE
        }
    },
    assetConfig: {
        video: {
            src: {
                formats: [
                    "/Generator_featured images/Whats new page/First_Fold-Whats_new.mp4"
                ]
            },
            loop: true
        }
    }
};
